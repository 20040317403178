/*
	AUTHOR: QASIM ZUBAIR
	SUMMARY: CONTAINS COMMONLY USED FUNCTIONS. FOR EXAMPLE, DISPLAYING MESSAGE FORM MESSAGE TABLE.
*/

// CAPITALIZE THE STRING
import io from 'socket.io-client';
import {URLS} from "../../_metronic/Api/UrlList"
import { v4 as uuidv4 } from 'uuid';
import {ref, update, child, get, getDatabase} from "firebase/database";
import {app, database, firestore} from "./firebase.service"
import {doc, getDoc, setDoc} from "firebase/firestore"
import moment from "moment";
import {globals} from "../utils/globals"

// CREATE A SOCKET CONNECTION TO THE NODE SERVER.
export const socket = io.connect (URLS?.SOCKET_URL, {path:URLS.SOCKET_PATH});

// CREATE A SOCKET CONNECTION WITH NODE SERVER.
export const connectSocket = () =>
{
	return io.connect (URLS?.SOCKET_URL, {path:URLS.SOCKET_PATH});
}

export const capitalize = (str: any) =>
{
	let strVal = '';
	str = str.split(' ');
	for (var chr = 0; chr < str.length; chr++)
	{
		strVal += str[chr].substring(0, 1).toUpperCase() + str[chr].substring(1, str[chr].length) + ' '
	}
	return strVal
}

// SET THE DATA IN LOCAL STORAGE.
export const setStorage = async (key:string, value:any) =>
{
	// IF VALUE IS OBJECT THEN STRINGIFY IT.
	if (typeof value === "object")
	{
		value = JSON.stringify (value);
	}
	return await sessionStorage.setItem(key, value)
}

// GET THE DATA FROM LOCAL STORAGE.
export const getStorage = async (key:string) =>
{
	return await sessionStorage.getItem (key)
}

// REMOVE THE DATA FROM ASYNC STORAGE.
export const removeStorage = async (key:string) =>
{
	await sessionStorage.removeItem (key);
}

// FUNCTION TO SET A COOKIE
export const setCookie = (name:any, value: any, days: any) =>
{
	const date = new Date();
	date.setTime (date.getTime() + (days * 24 * 60 * 60 * 1000));
	const expires = "expires=" + date.toUTCString();
	document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

// FUNCTION TO GET A COOKIE BY NAME
export const getCookie = (name: any) =>
{
	const decodedCookie = decodeURIComponent (document.cookie);
	const cookies = decodedCookie.split (';');
	for (let i = 0; i < cookies.length; i++)
	{
		let cookie = cookies[i];
		while (cookie.charAt (0) === ' ')
		{
			cookie = cookie.substring (1);
		}
		if (cookie.indexOf (name) === 0)
		{
			return cookie.substring (name.length + 1, cookie.length);
		}
	}
	return "";
}

// FUNCTION TO GENERATE A UNIQUE DEVICE ID
export const generateDeviceId = async () =>
{
	// IF DEVICE ID IS ALREADY STORED IN ASYNC COOKIE STORAGE THEN GET IT FROM THERE. OTHERWISE CREATE IT.

	try
	{
		// CHECK IF DEVICE ID IS ALREADY STORED IN COOKIES
		let deviceId = await getCookie ('deviceId');
		console.log ("current deviceid ", deviceId);

		// IF DEVICE ID IS NOT STORED IN COOKIES, GENERATE A NEW ONE
		if (!deviceId)
		{
			// USE A COMBINATION OF NAVIGATOR PROPERTIES TO CREATE A UNIQUE IDENTIFIER
			deviceId = uuidv4().toString() + "";
			// deviceId = `${navigator.userAgent}-${navigator.platform}-${Math.random()}`;

			// STORE THE GENERATED DEVICE ID IN COOKIES TO PERSIST ACROSS SESSIONS
			setCookie ('deviceId', deviceId, 365); // SET COOKIE TO EXPIRE IN 365 DAYS
			setStorage ("deviceId", deviceId).then(); // LOCAL STORAGE TO STORE THE DEVICE ID FOR NEXT TIME USE.
		}
		console.log ("current deviceid2 ", deviceId);

		return deviceId;
	}
	catch (error)
	{
		console.error ("error in getting device id. ", error);
		return "";
	}
}

// FUNCTION TO UPDATE CALLSTATUS FIELD AND ADD A STATUS TO THE CALLSTATUSES ARRAY IF IT'S NOT A ALREADY HAVE.
export const updateCallStatus = async (callId: any, newStatus:string, otherFileds?:any) =>
{
	try
	{
		if (typeof callId !== "undefined" && callId !== "")
		{
			const databaseRef = ref (database, 'call');
			const callRef = child (databaseRef, callId);

			if (callRef)
			{
				console.log ("database:", database);
				console.log ("database ref:", databaseRef);

				// FIRSTLY, CHECK IF THE NEW STATUS IS DIFFERENT FROM THE PREVIOUS STATUS
				const snapshot = await get (child (callRef, 'callstatus'));
				const previousStatus = snapshot.val ();
				console.log ("Console previousStatus: ", callRef, "previousStatus :", previousStatus)

				// HAVE OTHER FILED AND THE CALLSTATUS FIELD UPDATE.
				if (otherFileds && typeof otherFileds === "object")
				{
					await update (callRef, {"callstatus": newStatus, ...otherFileds});
				}
				else
				{
					// IF THE STATUS HAS CHANGED, UPDATE THE CALLSTATUS FIELD
					await update (callRef, {"callstatus": newStatus});
				}

				if (newStatus !== previousStatus)
				{
					// THE STATUS HAS CHANGED, ADD THE NEW STATUS TO THE CALLSTATUSES ARRAY
					const callStatusRef = child (callRef, 'callstatuses');
					const callstatusesSnapshot = await get (callStatusRef);
					if (!callstatusesSnapshot.exists ())
					{
						// HAVE OTHER FILED AND THE CALLSTATUS FIELD UPDATE.
						if (otherFileds && typeof otherFileds === "object")
						{
							await update (callRef, {"callstatuses": [newStatus], ...otherFileds});
						}
						else
						{
							// IF CALLSTATUSES ARRAY DOESN'T EXIST, CREATE A NEW ONE WITH THE NEW STATUS
							await update (callRef, {"callstatuses": [newStatus]});
						}
					}
					else
					{
						// GET THE EXISTING CALLSTATUSES ARRAY
						let statuses = callstatusesSnapshot.val ();
						console.log ("Console updateCallRealTime: ", callRef, "column :", statuses)

						// CHECK IF THE NEW STATUS ALREADY EXISTS IN THE ARRAY
						if (!statuses.includes (newStatus))
						{
							// IF THE NEW STATUS DOESN'T EXIST, ADD IT TO THE ARRAY
							statuses.push (newStatus);

							// HAVE OTHER FILED AND THE CALLSTATUS FIELD UPDATE.
							if (otherFileds && typeof otherFileds === "object")
							{
								await update (callRef, {"callstatuses": statuses, ...otherFileds});
							}
							else
							{
								await update (callRef, {"callstatuses": statuses});
							}
						}
						else
						{
							console.log ("Status already exists in the array. Ignoring insertion.");
						}
					}
				}
			}
		}
		else
		{
			console.log ("update invaild parameter", callId, newStatus);
		}
	} catch (error)
	{
		console.error ("Error updating callstatus and callstatuses:", error);
	}
};

// FUNCTION TO UPDATE CALLSTATUS FIELD AND ADD A STATUS TO THE CALLSTATUSES ARRAY IF IT'S NOT A ALREADY HAVE.
export const updateCallStatusByUserId = async (callId: any, newStatus:string, userId: any) =>
{
	try
	{
		if (typeof callId !== "undefined" && callId !== "")
		{
			const databaseRef = ref (database, 'call'); // CALL DATABASE REFERENCE.
			
			// CHECK IF THE CALL ID, NEW STATUS AND USER ID ARE VALID PARAMETERS. CALL ID NOT NULL OR EMPTY.
			if (callId === "undefined" || newStatus === "undefined" || callId === "" || newStatus === "")
			{
				console.log ("update user_id invaild parameter", callId, newStatus);
				return;
			}
			
			// GET THE CALL REFERENCE.
			const childRef = child (databaseRef, callId); // CALL ID REFERENCE.
			const notifiedRef = child (childRef, "notified_user_id"); // OTHER STAFF NOTIFIED FIELD.
			let updateFieldRef; // HOLD THE REFERENCE OF THE FIELD TO UPDATE.

			// CHECK IS GETTING CALL DETAIL FROM FCM NOTIFICATION HAVE DEFAULT STAFF INCLUDED IN CALL LIST.
			// IF NOT THEN UPDATE THE NOTIFIED_USER_ID FIELD. IT YES THEN UPDATE DEFAULTSTAFF_NOTIFIED FIELD FOR ONLY
			// DEFAULT STAFF. OTHER STAFF SAME AS BEFORE.
			if (typeof globals.isThisDefaultStaffNotified !=="undefined" && globals.isThisDefaultStaffNotified == 'true')
			{
				console.log("updateCallStatusByUserId 1.1", typeof globals.isThisDefaultStaffNotified);
				updateFieldRef = child (childRef, "defaultstaffnotified");
			}
			else
			{
				console.log("updateCallStatusByUserId 1.2",typeof globals.isThisDefaultStaffNotified);
				updateFieldRef = child (notifiedRef, `${userId}`);
			}
			console.log ("updateCallStatusByUserId", updateFieldRef, callId, newStatus, userId, globals.isThisDefaultStaffNotified);
			
			// NON, UPDATE THE CALLSTATUS FIELD
			await update (updateFieldRef, {"callstatus": newStatus});

			// THE STATUS HAS CHANGED, ADD THE NEW STATUS TO THE CALLSTATUSES ARRAY
			const callStatusRef = child (updateFieldRef, 'callstatuses');
			const callstatusesSnapshot = await get (callStatusRef);

			if (!callstatusesSnapshot.exists ())
			{
				// IF CALLSTATUSES ARRAY DOESN'T EXIST, CREATE A NEW ONE WITH THE NEW STATUS
				await update (updateFieldRef, {callstatuses: [newStatus]});
			}
			else
			{
				// GET THE EXISTING CALLSTATUSES ARRAY
				let statuses = callstatusesSnapshot.val ();

				// CHECK IF THE NEW STATUS ALREADY EXISTS IN THE ARRAY
				if (!statuses.includes (newStatus))
				{
					// IF THE NEW STATUS DOESN'T EXIST, ADD IT TO THE ARRAY
					statuses.push (newStatus);
					await update (updateFieldRef, {callstatuses: statuses});
				}
				else
				{
					console.log ("Status already exists in the array. Ignoring insertion.");
				}
			}
		}
		else
		{
			console.log ("update user_id invaild parameter", callId, newStatus);
		}

	} catch (error)
	{
		console.error ("Error updating callstatus and callstatuses2:", error);
	}
};

// UPDATE STATUS IN REALTIME DATABASE.
export const updateCallRealTime = async (call_id: any, column: any) =>
{
	try
	{
		if (typeof column === "object" && typeof call_id !== "undefined")
		{
			const databaseRef = ref (getDatabase(app), 'call');

			let callRef = await child (databaseRef, call_id);
			console.log ("Console updateCallRealTime: ",callRef, "column :",column, call_id)
			await update (callRef, column);
		} else
		{
			console.log ("update invaild parameter");
		}
	} catch (e)
	{
		console.error ("updateCallRealTime error is, ", e);
	}
}

// FUNCTION WILL INSERT THE CALL DETAIL TABLE TO FIRESROTE.
export const insertCallRecordIntoFirestore = async (call_id:any) =>
{
	try
	{
		if (typeof call_id !== "undefined")
		{
		    const callFirestoreDoc = doc (firestore, 'call', call_id);

            // CHECK IF THE CALL DETAIL ALREADY EXISTS IN FIRESTORE.
            const docSnap = await getDoc (callFirestoreDoc);
            if (!docSnap.exists())
            {
                const databaseRef = ref (database, 'call');
                const callRef = child (databaseRef, call_id);

                // AFTER UPDATE STATUS IN NEW DATABASE THEN UPDATE IN FIRESTORE.
                let snapshot = await get (callRef)
                const callData = snapshot.val ();
                callData.timecallinitiated = moment (callData.timecallinitiated).toDate();

                // INSERT THE CALL DETAIL INTO FIRESTORE.
				await setDoc (callFirestoreDoc, callData);
				console.log ("Document inserted firestore with ID: ", call_id);
				return;
			}
		}
	}
	catch (error)
	{
		console.error ("Error inserting call detail to firestore:", error);
	}
}

// CONVERT THE TIME FORMAT {00:00:00}
export const convertTimerFormat = (time: any) =>
{
	const h = Math.floor(time / 3600);
	const m = Math.floor((time - h * 3600) / 60);
	const s = time % 60;
	
	const hours = String(h).padStart(2, '0');
	const minutes = String(m).padStart(2, '0')
	const seconds = String(s).padStart(2, '0');
	const timeString = h > 0? `${hours}:${minutes}:${seconds}` : `${minutes}:${seconds}`;
	return timeString;
}
