import store from "../redux/store"

export const getLabel = (message) => {
  let ln = store?.getState()?.LanguageReducer?.reduxLang?.data
  return (ln && ln[message.toLowerCase()]) || obj[message.toLowerCase()]
}
const obj = {
  is_req: "is a required field",
  sign_out: "Sign Out",
  sign_up: "Sign up",
  login_in_to_qrcode: "Login in to QR Phone",
  enter_email_reset_pass: "Enter your email to reset your password",
  register_org_user: "Register as a Organization Representative OR User",
  forgot_password: "Forgot Password",
  please_wait: "Please wait...",
  contact_us: "Contact Us",
  reset_password: "Reset Password",
  err_detected_try_again: "Sorry, looks like there are some errors detected, please try again.",
  pass_reseted_success: "Password reseted successfully.",
  admin_management: "Admin Management",
  org_management: "Organization Management",
  user_management: "User Management",
  group_management: "Group Management",
  dlt_cnfrm: "Are you sure you want to delete this",
  staff_management: "Staff Management",
  plan_management: "Plan management",
  qr_management: "QR Code Management",
  org_manager_management: "Organization Manager Management",
  usr_info_management: "User Info Management",
  under_development: "Under development",
  filter_options: "Filter Options",
  new_password: "New password",
  confirm_password: "Confirm password",
  select_plan: "Select Plan",
  select_fields: "Select Fields",
  update_profile: "Update Profile",
  my_profile: "My Profile",
  admin_list: "Admin list",
  view_admin: "View Admin",
  full_name: "Full Name",
  error_message_conf_pass:
    "Confirm password must be at least 8 characters long with 1 uppercase 1 lowercase and with 1 Special character.",
  error_message_new_pass:
    "New password must be at least 8 characters long with 1 uppercase 1 lowercase and with 1 Special character.",
  current_password: "Current password",
  change_password_small: "Change password",
  error_message_curr_pass: "Please enter current password.",
  error_message_pass_not_match: "New password & confirm password does't match.",
  contact_no: "Contact Number",
  time: "Time",
  language: "Language",
  reset: "Reset",
  weekdays: "Weekdays",
  default: "Default",
  mapping: "Mapping",
  start_shift: "Start Shift",
  end_shift: "End Shift",
  copy_link: "Copy Link",
  copy_success: "Copy Successfully!",
  download: "Download",
  menu_login: "Login",
  menu_pricing: "Pricing",
  menu_contact: "Contact",
  start_title_1: "SCAN ,",
  start_title_2: "CONNECT",
  start_title_3: "VIDEOCALL!",
  start_subtitle_1: "Instant videocalls with a simple",
  start_subtitle_2: "QR code scan!",
  start_card_1_title: "FOR PERSONAL USE",
  start_card_1_subtitle_1: "SHARE A QR CODE,",
  start_card_1_subtitle_2: "NOT YOUR PHONE",
  start_card_1_subtitle_3: "NUMBER",
  start_card_1_description: "You can activate and deactivate the QR codes!",
  start_card_2_title: "FOR COMPANIES",
  start_card_2_subtitle_1: "INVITE YOUR STAFF",
  start_card_2_subtitle_2: "AND CREATE",
  start_card_2_subtitle_3: "SCHEDULES",
  start_card_2_description: "Provide a human touch to your customers",
  start_card_3_title: "NO DOWNLOADS NEEDED",
  start_card_3_subtitle_1: "NO APP IS REQUIRED",
  start_card_3_subtitle_2: "FOR CALLERS. ONLY",
  start_card_3_subtitle_3: "SCAN AND CONNECT",
  start_card_3_description: "By scanning the QR code, a webpage with the call opens up",
  start_footer_1: "Free trial!",
  start_footer_2: "Sign up here",
  contact_form_title: "Contact us",
  contact_form_name: "Name",
  contact_form_email: "Email",
  contact_form_message: "Message",
  contact_form_check_privacy: "I have read and agree to the privacy policy",
  contact_form_check_promotion: "I would like to receive commercial information and promotional offers via email",
}
