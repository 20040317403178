import {Routes, Route, Navigate, HashRouter} from "react-router-dom"
import {PrivateRoutes} from "./PrivateRoutes"
import {ErrorsPage} from "../modules/errors/ErrorsPage"
import {App} from "../App"
import {useSelector} from "react-redux"
import Start from "../modules/auth/components/Start"
import Contact from "../modules/auth/components/Contact"
import SelectPlan from "../modules/auth/components/SelectPlan"
import ClientRegister from "../modules/auth/components/ClientRegister"
import InviteOrg from "../modules/qr-organization-manager-management/InviteOrg"
import UserScanQrCode from "../modules/user-scan-qrcode/UserScanQrCode"
import ThanksCall from "../modules/user-scan-qrcode/ThanksCall"
import InvalidQr from "../modules/user-scan-qrcode/InvalidQr"
import LoaderSpinner from "../../_metronic/helpers/loader-helper/LoaderSpinner"
import Home from "../modules/calling/Home"
import InviteExistingUser from "./../modules/qr-organization-manager-management/InviteExistingUser"
import ToasterHelper from "../../_metronic/helpers/toaster-helper/ToasterHelper"
import { AuthPage } from "../modules/auth/AuthPage"
import IncomingCallComponent from "../modules/calling/icomingcall.component";
import CallComponent from "../modules/calling/call.component";
import StaffNotAvailableComponent from "../modules/user-scan-qrcode/staffnotavailable.component";

const AppRoutes = () => {
  const reduxState = useSelector((state) => state)

  return (
    <>
      <ToasterHelper />

      {reduxState.LoaderReducer.loading && <LoaderSpinner />}

      <HashRouter>
        <Routes>
          <Route element={<App />}>
            <Route path="error/*" element={<ErrorsPage />} />
            <Route path="select-plan" element={<SelectPlan />} />
            <Route path="start" element={<Start />} />
            <Route path="contact" element={<Contact />} />
            <Route path="client-registration" element={<ClientRegister />} />
            <Route path="invite-user/*" element={<InviteOrg />} />
            <Route path="invite-existing-user/*" element={<InviteExistingUser />} />

            {/* <Route path="audio-video-call" element={<AudioVideoCall />} /> */}
            {/* <Route path="audio-call" element={<AudioCall />} /> */}

            <Route path="user-scan-qrcode/:token/:detailsToken" element={<UserScanQrCode />} />
            <Route path="calling" element={<Home />} />
            <Route path="incomingcall" element={<IncomingCallComponent />} />
            <Route path="callcomponent" element={<CallComponent />} />
            <Route path="thanks-call" element={<ThanksCall />} />
            <Route path="invalid-qr" element={<InvalidQr />} />
            <Route path="staffnotavailable" element={<StaffNotAvailableComponent />} />

            {reduxState?.AuthReducer?.token ? (
              <>
                <Route path="/*" element={<PrivateRoutes />} />
                <Route index element={<Navigate to="/dashboard" />} />
              </>
            ) : (
              <>
                <Route path="auth/*" element={<AuthPage />} />
                <Route path="*" element={<Navigate to="/start" />} />
              </>
            )}
          </Route>
        </Routes>
      </HashRouter>
    </>
  )
}
export {AppRoutes}
