/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from "yup"
import toast from "react-hot-toast"
import {Formik, Form} from "formik"
import {useSelector} from "react-redux"
import {useLocation, useNavigate} from "react-router-dom"
import {useEffect, useState} from "react"
import {PageTitle} from "../../../_metronic/layout/core"
import AxiosMethod from "../../../_metronic/Api/AxiosMethod"
import FormikController from "../../../_metronic/Shared-Component-formik/FormikController"
import {getTempDept, getTempOrg} from "../../../_metronic/helpers/reuseable-fun/FunReuse"
import {TakeEditedVal} from "../../../_metronic/helpers/only-edit-value/TakeEditedVal"
import {getLabel} from "../../../_metronic/language-provider/LanProvider"
import {ValSchemaList} from "../../../_metronic/Shared-Component-formik/schema/ValSchemaList"

const CreateEditQrCode = () =>
{
	const loggedInUser = useSelector((state) => state?.AuthReducer?.userData)
	const [loading, setLoading] = useState(false)
	const usersBreadcrumbs = [
	{
	  title: `${getLabel("QR_CODE")} ${getLabel("LIST")}`,
	  path: "/qr-code-management",
	  isSeparator: false,
	  isActive: false,
	},
	{
	  title: "",
	  path: "",
	  isSeparator: true,
	  isActive: false,
	}]
	const navigate = useNavigate()
	const location = useLocation()
	const [dept, setDept] = useState()
	const [userList, setUserList] = useState([])
	const [orgForDept, setOrgForDept] = useState(
		location?.state?.editObj ? location?.state?.editObj?.organization_id : null
	)
	// const [showGroup, setshowGroup] = useState(
	//   location?.state?.editObj?.deptArr.length > 0 ? true : false
	// )
	
	// CHECK EDITOBT AND SET STATE THE QR END-TIME NEVER EXPIRED.
	const [qrExp, setQrExp] = useState (
		location?.state?.editObj? ((location?.state?.editObj?.qr_end_time === null) ? true : false) : false
	)
	const [qrUsrField, setQrUsrField] = useState(false)
	const [staffList, setStaffList] = useState([])

    const qrType = []
	if (loggedInUser["role_id"] === 1 || loggedInUser["plan.is_qr_type_static"]) {
		qrType.push ({label: "Static", value: "static"})
	}
	if (loggedInUser["role_id"] === 1 || loggedInUser["plan.is_qr_type_dynamic"]) {
		qrType.push ({label: "Dynamic", value: "dynamic"})
	}
	const qrStatus = [
		{label: "Active", value: "active"},
		{label: "Inactive", value: "inactive"},
	]
	
	// GET THE ORGANIZATION LIST.
	const getOrganizationList = async () =>
	{
		setUserList (await getTempOrg())
	}

    // GET STAFF LIST.
    const getStaffList = async () =>
    {
		let tempArray = []
		let getClientRes = await AxiosMethod (`users/allStaffs`, `post`,
		{
		    organizationId: orgForDept,
		})
		getClientRes?.data?.data?.rows?.map ((usr) =>
		{
			Object.assign (usr, {label: usr ["user.full_name"], value: usr.user_id})
			tempArray.push (usr)
		})
		setStaffList (tempArray)
    }

	// INITIALIZE VALUE OF FORM.
	const initialValues =
	{
		qr_name: location?.state?.editObj ? location?.state?.editObj?.qr_name : "",
		type: "",
		status: location?.state?.editObj ? location?.state?.editObj?.status : "",
		organization_id: location?.state?.editObj ? location?.state?.editObj?.organization_id : "",
		department_id: location?.state?.editObj ? location?.state?.editObj?.department_id : "",
		staffs: location?.state?.editObj ? location?.state?.editObj?.staffs : "",
		qr_start_time: location?.state?.editObj ? location?.state?.editObj?.qr_start_time : "",
		qr_end_time: location?.state?.editObj? (location?.state?.editObj?.qr_end_time === "never" ? "" : location?.state?.editObj?.qr_end_time) : "",
		fields: location?.state?.editObj? location?.state?.editObj?.fields : "",
		default_staff_id: location?.state?.editObj ? location?.state?.editObj?.default_staff_id : "",
		door_id: location?.state?.editObj ? location?.state?.editObj?.door_id : "",
	}
	console.log ("location?.state?.editObj", location?.state?.editObj);
	
	// FORM VALIDATION SCHEMA.
	let validationSchema = Yup.object({
		...(location?.state?.add && {qr_name: ValSchemaList(0, "NAME")}),
		...(location?.state?.add && {type: ValSchemaList(0, "TYPE")}),
		...(location?.state?.add && {status: ValSchemaList(0, "STATUS")}),
		...(location?.state?.add &&
			(loggedInUser["role_id"] === 1 || !loggedInUser["plan.plan_type"] === "individual") && {
			organization_id: ValSchemaList(0, "ORG"),
			}),
		...(
			(loggedInUser["role_id"] === 1 || !loggedInUser["plan.plan_type"] === "individual") && {
			  default_staff_id: ValSchemaList(0, "STAFF"),
			}),
		...(location?.state?.add &&
			(loggedInUser["role_id"] === 1 || !loggedInUser["plan.plan_type"] === "individual") && {
			department_id: ValSchemaList(7, "SCHEDULE"),
			}),
	    // ...(!showGroup &&
	    //   location?.state?.add && {
	    //     staffs: ValSchemaList(7, "staff"),
	    //   }),
	    ...(location?.state?.add && {
	      qr_start_time: ValSchemaList(6, "VALID_FROM"),
    }),
    ...(!qrExp &&
      location?.state?.add && {
        qr_end_time: ValSchemaList(6, "EXP_TIME").when(
          "qr_start_time",
          (qr_start_time, schema) =>
            qr_start_time && schema.min(qr_start_time, "End Time is must be later than Start Time")
        ),
      }),
    // ...(location?.state?.add && {
    //   fields: ValSchemaList(7, "FIELD"),
    // }),
  })

    const generateQR = async (values) =>
    {
        setLoading(true)
	    let obj = TakeEditedVal(values, initialValues)
	    if (obj.organization_id) {
	        obj.organization_id = values.organization_id || location.state.editObj.organization_id
	    }
	    if (loggedInUser["plan.plan_type"] === "individual") {
	      Object.assign(obj, {default_staff_id: loggedInUser.user_id})
	    }
	    obj.qr_end_time = qrExp ? "never" : values.qr_end_time
	    obj.created_by = loggedInUser.user_id
	    
	    if (location?.state?.editObj && location?.state?.editObj?.calllink_id)
	    {
		    obj.callline_id = location?.state?.editObj?.calllink_id;
	    }
	    
	    console.log ("objobjobjobjobj", obj)
	    
	    // showGroup ? delete obj.staffs : delete obj.department_id
	    // let obj = {
	    //   qr_name: values.qr_name,
	    //   ...(location?.state?.add && {type: values.type}),
	    //   status: values.status,
	    //   organization_id: values.organization_id || location.state.editObj.organization_id,
	    //   ...(location?.state?.add && showGroup && {department_id: values.department_id}),
	    //   ...(location?.state?.add && !showGroup && {staffs: values.staffs}),
	    //   ...(location?.state?.add && {qr_start_time: values.qr_start_time}),
	    //   ...(location?.state?.add && {qr_end_time: qrExp ? "never" : values.qr_end_time}),
	    //   ...(location?.state?.add && {fields: values.fields}),
	    //   ...(location?.state?.add && {created_by: loggedInUser.user_id}),
	    //   ...(location?.state?.add && {default_staff_id: loggedInUser.default_staff_id}),
	    // }
	
	    let generateQRRes = await AxiosMethod(
	      location?.state?.add ? `qrcodes` : `qrcodes/${location.state.editObj.qrcode_id}`,
	      location?.state?.add ? `post` : `patch`,
	      obj
	    )
	    if (JSON.parse(generateQRRes.request.response).status === "error") {
	      setLoading(false)
	      toast.error(JSON.parse(generateQRRes.request.response).message)
	    }
	    if (generateQRRes.data.status === "success") {
	      setLoading(false)
	      toast.success(location?.state?.add ? getLabel("QR_GENERATED") : getLabel("QR_UPDATED"))
	      navigate("/qr-code-management")
	    }
    }

	const getUsrDetail = async () => {
		let tempArray = []
		let getClientRes = await AxiosMethod(`qrUserDetails`, `get`)
		getClientRes?.data?.data?.rows?.map((usr) => {
		  // FOR SOLVING RangeError: Maximum call stack size exceeded in react
		  // const test = Object.assign({}, {n: usr.field_name, t: usr.type})
		  const test = Object.assign({}, {[usr.field_name]: usr.type})
		  Object.assign(usr, {label: usr.field_name, value: JSON.stringify(test)})
		  tempArray.push(usr)
		})
		setQrUsrField(tempArray)
	}
	
	// GET THE DEPARTMENT LIST.
	const getDept = async () =>
	{
		setDept (await getTempDept(orgForDept))
	}
	
	useEffect(() =>
	{
		getOrganizationList();
		getUsrDetail();
		console.log ("location?.state?.editObj?.qr_end_time", location?.state?.editObj );
	}, [])
	useEffect(() =>
	{
		getDept()
		getStaffList()
	}, [orgForDept])

	return (
	<>
	  <PageTitle breadcrumbs={usersBreadcrumbs}>
	    {`${getLabel(location?.state?.add ? "GENERATE" : "EDIT")} ${getLabel("QR_CODE")}`}
	  </PageTitle>
	
	  <div className="card card-custom">
	    <div className="card-header">
	      <div className="card-title">
	        <h3 className="card-label">
	          {`${getLabel(location?.state?.add ? "GENERATE" : "EDIT")} ${getLabel("QR_CODE")}`}
	        </h3>
	      </div>
	    </div>
	
	    <Formik
	      initialValues={initialValues}
	      validationSchema={validationSchema}
	      onSubmit={generateQR}
	    >
	      {(formik) => {
	        formik.values.organization_id && setOrgForDept(formik.values.organization_id)
	        return (
	          <Form onSubmit={formik.handleSubmit} className="form">
	            {/*begin::Scroll*/}
	            <div className="card-body py-4">
	              <div
	                className="d-flex row me-n7 pe-7"
	                // className="d-flex row scroll-y me-n7 pe-7"
	                id="kt_modal_add_user_scroll"
	                data-kt-scroll="true"
	                data-kt-scroll-activate="{default: false, lg: true}"
	                // data-kt-scroll-max-height="auto"
	                data-kt-scroll-dependencies="#kt_modal_add_user_header"
	                data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
	                // data-kt-scroll-offset="300px"
	              >
	                {/*  Name */}
	                <div className="col-6 mb-7">
	                  <FormikController
	                    control="input"
	                    type="text"
	                    label={getLabel("NAME")}
	                    placeholder={`${getLabel("ENTER")} ${getLabel("NAME")}`}
	                    labelClassName="required fw-bold fs-6 mb-2"
	                    name="qr_name"
	                    className="form-control form-control-solid mb-lg-0"
	                    maxLength="25"
	                    formik={formik}
	                    value={formik.values.qr_name}
	                    onChange={formik.handleChange}
	                    error={formik.errors.qr_name}
	                    disabled={!location?.state?.add}
	                  />
	                </div>
	
	                {/* type */}
	                {location?.state?.add && (
	                  <div className="col-6 mb-7">
	                    <FormikController
	                      control="react_select"
	                      label={`${getLabel("QR_CODE")} ${getLabel("TYPE")}`}
	                      labelClassName="required fw-bold fs-6 mb-2"
	                      name="type"
	                      className="form-control-solid mb-lg-0"
	                      formik={formik}
	                      options={qrType}
	                      value={formik.values.type}
	                      onChange={formik.handleChange}
	                      error={formik.errors.type}
	                    />
	                  </div>
	                )}
	
	                {/* status */}
	                <div className="col-6 mb-7">
	                  <FormikController
	                    control="react_select"
	                    label={`${getLabel("QR_CODE")} ${getLabel("STATUS")}`}
	                    labelClassName="required fw-bold fs-6 mb-2"
	                    name="status"
	                    className="form-control-solid mb-lg-0"
	                    formik={formik}
	                    options={qrStatus}
	                    value={formik.values.status}
	                    onChange={formik.handleChange}
	                    error={formik.errors.status}
	                  />
	                </div>
	
	                {/* client */}
	                {loggedInUser["role_id"] === 1 ||
	                loggedInUser["plan.plan_type"] !== "individual" ? (
	                  <>
	                    <div className="col-6 mb-7">
	                      <FormikController
	                        control="react_select"
	                        label={getLabel("ORG")}
	                        labelClassName="required fw-bold fs-6 mb-2"
	                        name="organization_id"
	                        className="form-control-solid mb-lg-0"
	                        formik={formik}
	                        options={userList}
	                        value={formik.values.organization_id}
	                        onChange={formik.handleChange}
	                        error={formik.errors.organization_id}
	                        isDisabled={!location?.state?.add}
	                      />
	                    </div>
	
	                    <div className="col-6 mb-7">
	                      <FormikController
	                        control="react_select"
	                        label={getLabel("DEFAULT") + " " + getLabel("STAFF")}
	                        labelClassName="required fw-bold fs-6 mb-2"
	                        name="default_staff_id"
	                        className="form-control-solid mb-lg-0"
	                        formik={formik}
	                        options={staffList}
	                        value={formik.values.default_staff_id}
	                        onChange={formik.handleChange}
	                        error={formik.errors.default_staff_id}
	                      />
	                    </div>
	
	                    <div className="col-6 mb-7">
	                      <FormikController
	                        control="react_select"
	                        label={getLabel("SCHEDULE")}
	                        labelClassName="required fw-bold fs-6 mb-2"
	                        name="department_id"
	                        className="form-control-solid mb-lg-0"
	                        formik={formik}
	                        options={dept}
	                        isMulti={true}
	                        value={formik.values.department_id}
	                        onChange={formik.handleChange}
	                        error={formik.errors.department_id}
	                      />
	                    </div>
	                  </>
	                ) : null}
	
	                {/* <div className="col-6 mb-7">
	                  <label className="fw-bold fs-6 mb-2">
	                    <span>Group/Staff</span>
	                  </label>
	                  <div className="row">
	                    <div className="col-3" onClick={() => setshowGroup(true)}>
	                      <input
	                        type="radio"
	                        className="form-check-input me-2"
	                        checked={showGroup}
	                        name="showGroup"
	                      />
	                      <label className="mb-2">
	                        <span>Group</span>
	                      </label>
	                    </div>
	                    <div className="col-3" onClick={() => setshowGroup(false)}>
	                      <input
	                        type="radio"
	                        className="form-check-input me-2"
	                        checked={!showGroup}
	                        name="showGroup"
	                      />
	                      <label className="mb-2">
	                        <span>Staff</span>
	                      </label>
	                    </div>
	                  </div>
	                </div> */}
	
	                {/* {showGroup ? (
	           
	                ) : (
	                  <div className="col-6 mb-7">
	                    <FormikController
	                      control="react_select"
	                      label={getLabel("STAFF")}
	                      labelClassName="required fw-bold fs-6 mb-2"
	                      name="staffs"
	                      className="form-control-solid mb-lg-0"
	                      formik={formik}
	                      options={staffList}
	                      isMulti={true}
	                      value={formik.values.staffs}
	                      onChange={formik.handleChange}
	                      error={formik.errors.staffs}
	                    />
	                  </div>
	                )} */}
	
	                {/* fields */}
	               
	                  <div className="col-6 mb-7">
	                    <FormikController
	                      control="react_select"
	                      label={getLabel("SELECT_FIELDS")}
	                      labelClassName="fw-bold fs-6 mb-2"
	                      name="fields"
	                      className="form-control-solid mb-lg-0"
	                      formik={formik}
	                      options={qrUsrField}
	                      isMulti={true}
	                      value={formik.values.fields}
	                      onChange={formik.handleChange}
	                      error={formik.errors.fields}
	                    />
	                  </div>
	               
	                {/* startTime */}
	                  <div className="col-6 mb-7">
	                    <FormikController
	                      control="datetime"
	                      label={getLabel("VALID_FROM")}
	                      placeholder={getLabel("SELECT")}
	                      labelClassName="required fw-bold fs-6 mb-2"
	                      className="form-control form-control-solid mb-lg-0"
	                      minDate={new Date()}
	                      name="qr_start_time"
	                      timeFormat="HH:mm"
	                      dateFormat="dd/MM/yyyy HH:mm"
	                      timeCaption="Time"
	                      formik={formik}
	                      value={formik.values.qr_start_time}
	                      onChange={formik.handleChange}
	                      error={formik.errors.qr_start_time}
	                    />
	                  </div>
	                
	
	                {/* End Timw */}
	                
	                  <div className="col-6 mb-7">
	                    {!qrExp && (
	                      <FormikController
	                        control="datetime"
	                        label={getLabel("EXP_TIME")}
	                        placeholder={getLabel("SELECT")}
	                        labelClassName="required fw-bold fs-6 mb-2"
	                        className="form-control form-control-solid mb-lg-0"
	                        minDate={(formik.values.qr_start_time && formik.values.qr_start_time !== "")? formik.values.qr_start_time : new Date()}
	                        name="qr_end_time"
	                        dateFormat="dd/MM/yyyy HH:mm"
	                        formik={formik}
	                        value={formik.values.qr_end_time}
	                        onChange={formik.handleChange}
	                        error={formik.errors.qr_end_time}
	                        //readOnly={qrExp}
	                      />
	                    )}
	                    {/* <FormikController
	                      control="checkbox"
	                      name="checkBoxChoice"
	                      option={choices}
	                      value={formik.values.checkBoxChoice}
	                      onChange={formik.handleChange}
	                      error={formik.errors.checkBoxChoice}
	                    /> */}
	
	                    <br />
	
	                    <label htmlFor="expire">
	                      <input
	                        id="expire"
	                        style={{marginTop: "1rem"}}
	                        type="checkbox"
	                        name="checkBoxChoice"
	                        value={qrExp}
	                        onClick={() => setQrExp(!qrExp)}
	                        checked={qrExp}
	                      />
	                      <span style={{marginLeft: ".5rem"}}>
	                        {`${getLabel("QR_CODE")} ${getLabel("NEVER_EXP")} `}
	                      </span>
	                    </label>
	                  </div>
		              
		              <div className="col-6 mb-7">
			              <FormikController
				              control="input"
				              type="text"
				              label={"Door Id"}
				              placeholder={"door id"}
				              labelClassName="fw-bold fs-6 mb-2"
				              className="form-control form-control-solid mb-lg-0"
				              minDate={new Date()}
				              name="door_id"
				              formik={formik}
				              value={formik.values.door_id}
				              onChange={formik.handleChange}
				              error={formik.errors.door_id}
			              />
		              </div>
	              </div>
	            </div>
	            {/*end::Scroll*/}
	            {/*begin::Actions*/}
	            <div className="card-footer d-flex justify-content-center">
	              <button
	                className="btn btn-light-primary font-weight-bold"
	                style={{marginRight: "1rem"}}
	                type="button"
	                onClick={() => {
	                  navigate("/qr-code-management")
	                }}
	              >
	                <span className="indicator-label">{getLabel("CANCEL")}</span>
	              </button>
	              <button
	                type="submit"
	                id="kt_password_reset_submit"
	                className="btn btn-lg btn-primary fw-bolder me-4"
	                disabled={loading}
	              >
	                {loading ? (
	                  <span className="indicator-progress" style={{display: "block"}}>
	                    {getLabel("PLEASE_WAIT")}
	                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
	                  </span>
	                ) : (
	                  <span className="indicator-label">{getLabel("SUBMIT")}</span>
	                )}
	              </button>
	            </div>
	            {/*end::Actions*/}
	          </Form>
	        )
	      }}
	    </Formik>
	  </div>
	</>
	)
}

export default CreateEditQrCode
